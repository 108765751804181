<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>门店类型</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">

      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" label-width="80px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="名称">
                <el-input v-model="search.test"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="选择">
                <el-select v-model="search.options" filterable clearable placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期">
                <el-date-picker
                    v-model="search.search_date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="height: 20px"></div>
          <el-row class="page-content-search-button" >
            <el-button icon="el-icon-search" size="medium" type="primary">搜索</el-button>
            <el-button icon="el-icon-circle-close" size="medium" >清空搜索</el-button>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"><h3>{{page_name}}</h3></el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary" plain @click="switchsearch">隐藏搜索</el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="switchsearch">显示搜索</el-button>
            <el-button v-if="this.Tool.is_auth('aaa')" icon="el-icon-download" size="medium" type="primary">导出</el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table
          :data="tableData"
          border
          size="medium"
          style="width: 100%">
        <el-table-column
            prop="date"
            label="日期"
            width="180">
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="address"
            label="地址">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="see(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
          :page-size="20"
          :pager-count="11"
          layout="prev, pager, next"
          :total="1000">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      page_name: '门店类型',
      issearch:false,
      // 搜索内容
      search:{
        test:'',
        search_date:'',
        options:'',
      },
      // 列表内容
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }, {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
    }
  },
  methods: {
    // 切换搜索
    switchsearch() {
      this.issearch = !this.issearch
    },
    // 查看
    see(data = []) {
      console.log('点击查看', data)
    },
    // 测试按钮
    testxxx() {
      let key = 'test';
      let data = [1, 2, 3]
      console.log('爆粗的数据', data)
      this.Tool.set_l_cache(key, data)

    },
    gettest() {
      let key = 'test';
      let data = this.Tool.get_l_cache(key)
      console.log('获取的数据', data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
